import React, { useEffect, useRef, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import config from './config';
import CryptoJS from 'crypto-js';
import CircularLogo from './circLogo.png';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();

  const renderVericloudsErrorMsg = (errorMsg) => {
    const errorContainer = document.getElementsByClassName('o-form-error-container')[0];
    // if (errorContainer.innerHTML) return false;

    const wrapper = document.createElement('div');
    wrapper.id = 'compromised-error';
    wrapper.classList.add("okta-form-infobox-error", "infobox", "infobox-error");

    const span1 = document.createElement('span');
    span1.classList.add("icon",  "error-16");

    const p1 = document.createElement('p');
    p1.innerText = errorMsg;

    wrapper.appendChild(span1);
    wrapper.appendChild(p1);

    errorContainer.appendChild(wrapper);
  }

  const renderErrorMsg = (count, isRegistration) => {
    const errorContainer = document.getElementsByClassName('o-form-error-container')[0];
    // if (errorContainer.innerHTML) return false;

    const wrapper = document.createElement('div');
    wrapper.id = 'compromised-error';
    wrapper.classList.add("okta-form-infobox-error", "infobox", "infobox-error");

    const span1 = document.createElement('span');
    span1.classList.add("icon",  "error-16");

    const p1 = document.createElement('p');
    p1.innerText = `Your ${isRegistration ? 'password' : 'account'} is compromised\nYou have ${5 - count} attempts left`;

    const p2 = document.createElement('p');
    p2.innerHTML = `Click <span style="font-weight:600;color:#2268bf;cursor:pointer;" onclick="(()=>document.getElementsByClassName('link js-forgot-password')[0].click())()">here</span> to reset your password`;

    wrapper.appendChild(span1);
    wrapper.appendChild(p1);

    isRegistration
      ? errorContainer.style.marginBottom = '15px'
      : wrapper.appendChild(p2);

    errorContainer.appendChild(wrapper);
  }

  const disableForm = () => {
    const button = document.querySelector('#okta-signin-submit') || document.querySelector('.button.button-primary')

    button.blur()
    button.classList.add('link-button-disabled')
    document.querySelectorAll('.o-form-input > span').forEach(i => i.classList.add('o-form-disabled'))
    document.querySelector('.link.help.js-help')?.classList.add('o-form-disabled')
  }

  const enableForm = () => {
    const button = document.querySelector('#okta-signin-submit') || document.querySelector('.button.button-primary')

    button.classList.remove('link-button-disabled')
    document.querySelectorAll('.o-form-input > span').forEach(i => i.classList.remove('o-form-disabled'))
    document.querySelector('.link.help.js-help')?.classList.remove('o-form-disabled')
  }

  const onProcessCreds = useCallback(async ({username, password}, callback, issuer) => {
    const errorContainer = document.getElementsByClassName('o-form-error-container')[0];
    const passwordHash = CryptoJS.SHA1(password).toString();
    let count = Number(localStorage.getItem(`${username}:${passwordHash}`));

    if (count === 5) {
      if (!errorContainer.innerHTML) renderErrorMsg(count, false);
      return false;
    } else {
      disableForm()
      // clear error message if it exists
      if (errorContainer.innerHTML) errorContainer.innerHTML = '';

      const apiToken = process.env.REACT_APP_API_TOKEN;
      const apiKey = process.env.REACT_APP_VERICLOUDS_API_KEY;
      const apiSecret = process.env.REACT_APP_VERICLOUDS_API_SECRET;
      const baseUrl = issuer.split('/oauth2')[0];
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `SSWS ${apiToken}`
      };
      const login = username.split('@').join('%40'); // TODO: implement URL escaping

      try {
        // check user status by login (email)
        const userResp = await fetch(`${baseUrl}/api/v1/users/${login}`, { headers });
        const userData = await userResp.json();

        // users without status ACTIVE are blocked for login by widget
        if (userData.status !== 'ACTIVE') return callback();

        const formData = new FormData();
        formData.append('mode', 'search_leaked_password_with_userid');
        formData.append('api_key', apiKey);
        formData.append('api_secret', apiSecret);
        formData.append('userid', username);
        formData.append('userid_type', 'email');

        const compromisedResp = await fetch('https://api.vericlouds.com/index.php', { method: 'POST', body: formData });
        const compromisedData = await compromisedResp.json();

        if (compromisedData.result !== 'succeeded') {
          renderVericloudsErrorMsg(compromisedData.reason);
          return false;
        }

        if (compromisedData.passwords_encrypted.length) {
          count++;
          localStorage.setItem(`${username}:${passwordHash}`, count);
          renderErrorMsg(count, false);
          enableForm();
          return false;
        } else {
          return callback()
        }
      } catch(e) {
        renderVericloudsErrorMsg(e.message);
        enableForm();
        return false;
      }
    }
  }, [])

  const onPreSubmit = useCallback(async (postData, onSuccess) => {
    const errorContainer = document.getElementsByClassName('o-form-error-container')[0];
    const passwordHash = CryptoJS.SHA1(postData.password).toString();
    let count = Number(localStorage.getItem(passwordHash));

    if (count === 5) {
      if (!errorContainer.innerHTML) renderErrorMsg(count, true);
      return false;
    } else {
      disableForm()
      // clear error message if it exists
      if (errorContainer.innerHTML) errorContainer.innerHTML = '';

      try {
        const apiKey = process.env.REACT_APP_VERICLOUDS_API_KEY;
        const apiSecret = process.env.REACT_APP_VERICLOUDS_API_SECRET;
        const formData = new FormData();

        formData.append('mode', 'search_leaked_password_with_hash_segment');
        formData.append('api_key', apiKey);
        formData.append('api_secret', apiSecret);
        formData.append('hash_segment', passwordHash.substring(0,6));

        const compromisedResp = await fetch('https://api.vericlouds.com/index.php', { method: 'POST', body: formData });
        const compromisedData = await compromisedResp.json();

        if (compromisedData.result !== 'succeeded') {
          renderVericloudsErrorMsg(compromisedData.reason);
          return false;
        }

        if (compromisedData.password_hashes.includes(passwordHash.toUpperCase())) {
          count++;
          localStorage.setItem(passwordHash, count);
          renderErrorMsg(count, true);
          enableForm();
          return false;
        } else {
          return onSuccess(postData)
        }
      } catch(e) {
        enableForm()
        renderVericloudsErrorMsg(e.message);
        return false;
      }
    }
  }, [])

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode } = config.oidc;
    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: CircularLogo,
      logoText: 'sign in',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in with Circular Flare'
        },
      },
      authParams: { issuer, scopes },
      useInteractionCodeFlow: useInteractionCode,
      features: {
        showPasswordToggleOnSignInPage: true,
        registration: true,

      },
      // check whether the user ID and password combination is included in the compromised database
      processCreds: (creds, callback) => onProcessCreds(creds, callback, issuer),
      // check whether a password is included in a list of already compromised passwords
      registration: {
        preSubmit: (postData, onSuccess) => onPreSubmit(postData, onSuccess)
      }
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err) => {
        throw err;
      },
    );

    return () => widget.remove();
  }, [oktaAuth, onProcessCreds, onPreSubmit]);

  return (
    <div>
      <div ref={widgetRef} />
    </div>
  );
};
export default Login;
