import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Image, Menu } from 'semantic-ui-react';
import CircularLogo from './circLogo.png';

const Navbar = () => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => history.push('/login');
  const logout = async () => oktaAuth.signOut();

  return (
    <div>
      <Menu fixed="top">
        <Container>
          <Menu.Item header>
            <Link to="/"><Image size="small" src={CircularLogo} /></Link>
          </Menu.Item>
          {authState?.isAuthenticated && (
            <Menu.Item id="profile-button">
              <Link to="/profile" style={{color: 'rgba(0,0,0,.87)'}}>My Profile</Link>
            </Menu.Item>
          )}
          {authState?.isAuthenticated && <Menu.Item id="logout-button" onClick={logout}>Logout</Menu.Item>}
          {!authState?.isPending && !authState?.isAuthenticated && <Menu.Item onClick={login}>Login</Menu.Item>}
        </Container>
      </Menu>
    </div>
  );
};
export default Navbar;
