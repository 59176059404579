import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import Logo from './logo2.png';
import './App.css';

const Home = () => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return (
    <div>
      <div>
        { authState?.isAuthenticated && !userInfo && <div>Loading user information...</div>}

        {authState?.isAuthenticated && userInfo &&
          <div>
            <Header as="h1">Circular Flare App - Internal Content Page</Header>
            Welcome, {userInfo.name}!
            <p>
              You have successfully authenticated against your Okta org, now you can visit the{' '}<Link to="/profile">My Profile</Link>
            </p>
            <div className="row pt-5">
              <div className="col-md-6">
                <h3>Interdum</h3>
                Placerat mi ipsum ornare dui id varius tortor est et urna vivamus commodo justo quis erat etiam lacus leo interdum non volutpat
              </div>
              <div className="col-md-6 pt-5 pt-md-0">
              <h3>Ullamcorper</h3>
              In sagittis vel erat donec congue pede eu lacus aliquam sodales felis nec libero mollis porttitor nulla adipiscing orci ac quam
            </div>
          </div>
        </div>
        }

        {!authState?.isAuthenticated &&
          <div>
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-4 col-md-2">
                <img src={Logo} alt="Company Logo" width={100 + '%'} />
              </div>
              <div className="col-12 col-md-7 pt-3">
                <Header as="h1">Circular Flare Home Page</Header>
              </div>
              <div className="text-center col-md-3 pt-3">
                <Button id="login-button" primary onClick={() => history.push('/login')}>Login</Button>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-md-2">
            </div>
            <div className="col-md-5">
              Mus Sodales Nulla Integer
              <h2>Auctor Diam Sed Non Sapien In Risus Rhoncus Egestas Mauris Rhoncus Mi At Odio In Luctus Turpis Quis Dignissim.</h2>
            </div>
            <div className="col-md-5">
              <h3>Interdum</h3>
              Placerat mi ipsum ornare dui id varius tortor est et urna vivamus commodo justo quis erat etiam lacus leo interdum non volutpat
              <h3>Ullamcorper</h3>
              In sagittis vel erat donec congue pede eu lacus aliquam sodales felis nec libero mollis porttitor nulla adipiscing orci ac quam
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12 pb-4">
              This demo project shows how VeriClouds’ password verification works with OKTA login widget
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};
export default Home;
